import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../Styles/LegalDocs.css";
import logo from "../Assets/logo_black.png"; // Corrige la ruta de importación


function LegalDocs() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  return (
    <div className="legal-section-title">
      <h1 className="legal-siteTitle">
      <img 
              src={logo} 
              alt="Family Insurance Logo" 
              className="ft-logo" 
              style={{ width: '233px', height: 'auto', verticalAlign: 'middle' }} 
            /> 
      </h1>

      <div className="legal-text-content">
        <p className="legal-title">Cookies Use Policy</p>
        <p className="legal-description" style={{ textAlign: 'justify' }}> 
        Last update: Dec 25th, 2024
        <br /><br />
        We respect your privacy and are committed to ensuring that your experience on our website is secure and enjoyable. As part of our commitment to protecting
         your personal information, we want to inform you that we do not use cookies or similar tracking technologies to monitor our visitors' activities.
         <br /><br />
         This means that we do not gather, store, or analyze any data related to your browsing behavior, preferences, or interactions on our site through such methods. 
         Our website is designed to provide information and services without the need for intrusive tracking measures. 
         <br /><br />
         By avoiding the use of cookies, we aim to enhance your online privacy and ensure that your experience remains uninterrupted and free from targeted advertisements 
         or personalized content that could otherwise result from tracking.


        </p>

       

       

        <p className="legal-title"></p>
      

        <p className="legal-title"></p>
        
      </div>
      

      <div className="legal-footer">
        <p>© 2024 Legal Services Enterprise LLC. All rights reserved.</p>
      </div>
    </div>
  );
}

export default LegalDocs;
